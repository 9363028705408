import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllAuditors } from "../../../../application/action/userAction";
import ToastifyService from "../../../_common/ToastifyService";
import SweetAlertService from "../../../_common/SweetAlertService";
import { getAuditorList } from "../../../../application/selector/indexSelector";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

export default function Auditor() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const AuditorsList = useSelector(getAuditorList);
  // useEffect(()=>{
  //     dispatch(GetAllAuditors(3))
  // },[AuditorsList])

  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.id}</div>,
      width: "50px",
    },
    {
      name: "Auditor Name",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            <Link>{row.fullName}</Link>
          </h6>
          <span className="fs-xs text-secondary">{row.email}</span>
        </div>
      ),
    },
    {
      name: "UserName",
      selector: (row) => row.userName,
    },
    {
      name: "Modified On",
      selector: (row) =>
        format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            // onClick={() => handleEditDiscount(true, row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(row)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
      width: "200px",
    },
  ];

  // HANDLE DELETE FUNCTION
  const [data, setData] = useState([]);
  const handleDelete = (index) => {
    SweetAlertService.showAlert(
      "Auditor",
      "Are you sure you want to delete this Auditor?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          ToastifyService.success(`Auditor Deleted Successfully`);
          const newData = [...data];
          newData.splice(index, 1);
          setData(newData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`Something went wrong`);
      });
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16">Auditor</h4>
        </div>
        {/* <div className="d-flex gap-2">
                    <Button variant="dark">
                        <i className="ri-add-line align-middle"></i> Create New Auditor
                    </Button>
                </div> */}
      </div>
      <Card className="card-one">
        <Card.Body>
          {/* {error && <p className="text-center" style={{ color: "red" }}>{error}</p>} */}
          {AuditorsList?.length > 0 ? (
            <DataTable
              columns={columns}
              data={AuditorsList}
              pagination
              highlightOnHover
            />
          ) : (
            !error && <p className="text-center">No records to display</p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
