import loader from './loader'
import teams from '../reducers/teams';
import user from '../reducers/user';
import error from '../reducers/error';
import inventory  from '../reducers/inventory'
import project  from '../reducers/project'
import audit  from '../reducers/audit'
import { combineReducers } from 'redux';

export default combineReducers({
    loader,
    teams,
    user,
    error,
    inventory,
    project,
    audit
    
})