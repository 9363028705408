import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Badge, Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import PendingAudit from "./PendingAudit/pending-audit";
import OngoingAudits from "./OngoingAudits/ongoing-audits";
import RecentlyWorked from "./RecentlyWorked/recently-worked";

export default function QAAudit() {
    const [isSidebarShow, setSidebarShow] = useState(false);
    return (
        <React.Fragment>
            <Header />
            <Tab.Container id="left-tabs-example" defaultActiveKey="pending_audit_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="pending_audit_tab"><i className="ri-archive-line"></i> Pending Audit </Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="ongoing_audits_tab">
                                <i className="ri-contacts-fill"></i> Ongoing Audits <Badge bg="danger" pill className="position-absolute end-10">6</Badge></Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="recently_worked_tab">
                                <i className="ri-contacts-fill"></i> close Audit <Badge bg="secondary" pill className="position-absolute end-10">7</Badge></Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>

                        <Tab.Content>
                            {/* PENDING AUDIT TAB */}
                            <Tab.Pane eventKey="pending_audit_tab"><PendingAudit /></Tab.Pane>

                            {/*ONGOING AUDITS TAB */}
                            <Tab.Pane eventKey="ongoing_audits_tab"><OngoingAudits /></Tab.Pane>

                            {/* RECNTLY WORKER TAB */}
                            <Tab.Pane eventKey="recently_worked_tab"><RecentlyWorked /></Tab.Pane>

                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}