import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_ERROR_LIST, ErrorListSuccess, GET_ERROR_CATEGORY_LIST, ErrorCategoryListSuccess, ERROR_UPLOAD, ERROR_CATEGORY_UPLOAD } from "../action/errorAction";


const ErrorList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ERROR_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "errorList" }));
        const errorList = await api.errorAPI.ErrorList(action.payload);
        
        dispatch(ErrorListSuccess(errorList));
        dispatch(Loading.setLoading({ loading: false, value: "errorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const ErrorCategoryList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ERROR_CATEGORY_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "errorCategoryList" }));
        const errorCategoryList = await api.errorAPI.ErrorCategoryList(action.payload);
        
        dispatch(ErrorCategoryListSuccess(errorCategoryList));
        dispatch(Loading.setLoading({ loading: false, value: "errorCategoryList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const UploadError =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ERROR_UPLOAD) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "errorList" }));
        
        const errorList = await api.errorAPI.UploadError(action.payload);
        dispatch(ErrorListSuccess(errorList));
        dispatch(Loading.setLoading({ loading: false, value: "errorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const UploadErrorCategory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ERROR_CATEGORY_UPLOAD) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "errorCategoryList" }));
        const errorCategoryList = await api.errorAPI.UploadErrorCategory(action.payload);
        
        dispatch(ErrorCategoryListSuccess(errorCategoryList));
        dispatch(Loading.setLoading({ loading: false, value: "errorCategoryList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
    ErrorList,ErrorCategoryList,UploadError, UploadErrorCategory
  ]