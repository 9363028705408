import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";

export default function AddErrorsModal(props) {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Error</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="add-error-list">
                    {[
                        {
                            "id": 1,
                            "error": "Missing Data"
                        },
                        {
                            "id": 2,
                            "error": "Duplicate Entry"
                        },
                        {
                            "id": 3,
                            "error": "Invalid Date Format"
                        },
                        {
                            "id": 4,
                            "error": "Incorrect Payor Name"
                        },
                        {
                            "id": 5,
                            "error": "Policy Number Mismatch"
                        },
                        {
                            "id": 6,
                            "error": "Unauthorized Access"
                        },
                        {
                            "id": 7,
                            "error": "Invalid Patient ID"
                        },
                        {
                            "id": 8,
                            "error": "Incorrect Group Number"
                        },
                        {
                            "id": 9,
                            "error": "Network Type Mismatch"
                        },
                        {
                            "id": 10,
                            "error": "Policy Number Missing"
                        }
                         ].map((item) => (
                            <div key={item.id}>
                                <Form.Check type="checkbox"
                                 id={`checkbox-${item.id}`}
                                 name={item.error}
                                 checked={checkedItems[item.error] || false}
                                 onChange={handleCheckboxChange}
                                 label={item.error} />
                            </div>
                         ))}
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <span className="align-middle">Add Error</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}