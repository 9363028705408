import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, Col, Form, Row, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getHeaderList, getMapProjectColumnList } from "../../../application/selector/indexSelector";
import useAuth from "../../../hooks/useAuth";
import { MapProjectColumn } from "../../../application/action/projectAction";

export default function ProjectData(props) {
    const dispatch = useDispatch();
    const columnsList = useSelector(getHeaderList);
    const mappedColumnList = useSelector(getMapProjectColumnList);

    const [selectedTeam, setSelectedTeam] = useState([]);
    const [mappedColumns, setMappedColumns] = useState([]);
    const { auth } = useAuth();

    useEffect(() => {
        setMappedColumns(mappedColumnList);
    }, [mappedColumnList]);


    // Filter out already mapped columns
    const availableColumns = columnsList?.filter(
        col => !mappedColumns?.some(mapped => mapped.colId === col.id)
    );

    const associatedDataOption = availableColumns?.map((col) => ({
        value: col.id,           // Using col.id for the value
        label: col.headerValue   // Assuming headerValue is a property of col
    }));

    const handleAddProjectTeam = () => {
        const Data = selectedTeam.map(element => ({
            projectId: props?.data?.id,
            colId: element.value, // Use `element.value` as column ID from Select
            createdBy: auth.id,
            modifiedBy: auth.id
        }));
        dispatch(MapProjectColumn(Data)); // Dispatch action to map columns to the project
        setSelectedTeam([]); // Optionally reset the selected options
    };

    // Remove column from the mapped columns list
    const removeProjectTeam = (item) => {
        setMappedColumns(mappedColumns.filter((team) => team.colId !== item.colId));
    };

    const handleColChange = (selectedOptions) => {
        setSelectedTeam(selectedOptions); // Update state with selected options
    };

    const initialState = {
        projectName: "",
        projectDescription: "",
        teamId: null,
        sourceId: null,
        empId: "",
        createdBy: auth.id,
        modifiedBy: auth.id
    };
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (props.data) {
            setFormData({
                ...formData,
                projectName: props?.data?.projectName,
                projectDescription: props?.data?.projectDescription
            });
        }
    }, [props?.data]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <React.Fragment>
            <Card className="card-one">
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Project Name"
                                    name="projectName"
                                    value={formData.projectName}
                                    onChange={handleInputChange} // Handle input changes
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <div className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter Description"
                                    name="projectDescription"
                                    value={formData.projectDescription}
                                    onChange={handleInputChange} // Handle input changes
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-4">
                                <Form.Label>Associated Data Columns</Form.Label>
                                <div className="d-flex gap-2 mb-4">
                                    <Select
                                        className="wt-400"
                                        options={associatedDataOption}  // Dropdown options
                                        value={selectedTeam}            // Bind to selected teams state
                                        onChange={handleColChange}      // Update state on selection change
                                        isMulti={true}                  // Enable multi-select
                                        isSearchable={true}             // Enables searching in the dropdown
                                        placeholder="Select Columns"      // Updated placeholder
                                    />
                                    <Button type="button" variant="dark" onClick={handleAddProjectTeam}>Include</Button>
                                </div>
                                <div>
                                    <h6 className="fs-14 mb-2">Defaults fields</h6>
                                    <div className="d-flex gap-2 align-items-center flex-wrap mb-3">
                                        {mappedColumns?.filter(item => item.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                            <Badge className="fs-14" bg="secondary" pill key={index}>
                                                <span>{item.colName}</span>
                                            </Badge>
                                        ))}
                                    </div>

                                    <div className="d-flex gap-2 align-items-center flex-wrap">
                                        {mappedColumns?.length === 0 ? (
                                            <h6 className="fs-14 text-center">No columns mapped</h6>
                                        ) : (
                                            mappedColumns?.filter(item => item.status !== "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                                <Badge bg="primary" className="fs-14" key={index} pill>
                                                    <span>{item.colName}</span>
                                                    <Link className="text-white align-middle ms-1" onClick={() => removeProjectTeam(item)}>
                                                        <i className="ri-close-circle-line"></i>
                                                    </Link>
                                                </Badge>
                                            ))

                                        )}
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
