import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Card, Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Link } from "react-router-dom";

export default function TLDashboard() {
    const serieAgent = [{
        name: 'Actual',
        data: [
            [0, 60], [1, 55], [2, 62], [3, 54], [4, 65], [5, 66], [6, 60], [7, 70], [8, 60], [9, 70],
            [10, 65], [11, 75], [12, 70], [13, 65], [14, 85], [15, 95], [16, 95], [17, 105], [18, 115], [19, 110],
            [20, 130], [21, 140], [22, 165], [23, 155], [24, 120], [25, 110], [26, 140], [27, 150], [28, 170], [29, 155],
            [30, 165], [31, 160], [32, 110], [33, 140], [34, 140], [35, 115], [36, 105], [37, 110], [38, 100], [39, 110],
            [40, 140], [41, 130], [42, 140], [43, 150], [44, 180], [45, 190], [46, 220]
        ]
    }];

    const optionAgent = {
        chart: {
            parentHeightOffset: 0,
            stacked: true,
            toolbar: { show: false }
        },
        colors: ['#4c5366'],
        dataLabels: { enabled: false },
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                bottom: 0,
                left: 20
            },
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
        stroke: {
            curve: 'smooth',
            width: 1.5
        },
        fill: {
            colors: ['#fff', '#81adee'],
            type: ['solid', 'gradient'],
            opacity: 1,
            gradient: {
                opacityFrom: 0.35,
                opacityTo: 0.65,
            }
        },
        legend: { show: false },
        tooltip: { enabled: false },
        yaxis: {
            max: 300,
            tickAmount: 6,
            show: false
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 11,
            labels: {
                style: {
                    colors: '#6e7985',
                    fontSize: '11px'
                }
            },
            axisBorder: { show: false }
        }
    };

    const agetntNameOptions = [
        { value: 'Aamir Sayyed', label: 'Aamir Sayyed' },
        { value: 'Atick Shaikh', label: 'Atick Shaikh' },
        { value: 'Mohd Izaz', label: 'Mohd Izaz' },
        { value: 'Imran Qureshi', label: 'Imran Qureshi' },
        { value: 'Faraz Khan', label: 'Faraz Khan' },
        { value: 'Asfaq Shaikh', label: 'Asfaq Shaikh' },
        { value: 'Anees Shaikh', label: 'Anees Shaikh' },
    ];

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title fs-16 mb-0">TL Dashboard</h4>
                    </div>
                </div>

                <Row className="g-3">
                    <Col md={5}>
                        <Card className="card-one h-auto">
                            <Card.Header className="align-items-center justify-content-between">
                                <Card.Title as="h6">Agent Performance</Card.Title>
                                <div>
                                    <Select className="wt-250" options={agetntNameOptions} isSearchable={true} />
                                </div>
                            </Card.Header>

                            <Card.Body>
                                <ReactApexChart series={serieAgent} options={optionAgent} type="area" height={328} />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={7}>
                        <Row className="g-3">
                            {[
                                {
                                    "label": "Audit Rework Rate",
                                    "icon": "ri-shopping-bag-3-line",
                                    "value": "1,214",
                                }, {
                                    "label": "Issue Escalated",
                                    "icon": "ri-briefcase-4-line",
                                    "value": "987",
                                },
                            ].map((card, index) => (
                                <Col key={index}>
                                    <Card className="card-one">
                                        <Card.Body>
                                            <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                                            <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                                            <small>than last week</small>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}

                            
                            {/* COMMNET FOR AUDITOR */}
                            <Col md={12}>
                                <Card className="card-one">
                                    <Card.Header className="align-items-center justify-content-between">
                                        <Card.Title as="h6">Comment for Auditor</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {[
                                            {
                                                "unread": true,
                                                "avatar": "https://placehold.co/36x36/png",
                                                "sender": "Dhiraj Sanjay Parmar",
                                                "time": "1:20pm",
                                                "subject": "Asking some few questions",
                                                "summary": "It is a long established fact that a reader will be distracted by the readable"
                                            }, {
                                                "avatar": "https://placehold.co/36x36/png",
                                                "sender": "Akshay Kirtiwar",
                                                "time": "11:45am",
                                                "subject": "30 seconds survey to your next job",
                                                "summary": "The point of using Lorem Ipsum is that it has a more-or-less normal distribu The point of using Lorem Ipsum is that it has a more-or-less normal distribu"
                                            },
                                            {
                                                "unread": true,
                                                "avatar": "https://placehold.co/36x36/png",
                                                "sender": "Kailash Vitthal Dongre",
                                                "time": "1:20pm",
                                                "subject": "Asking some few questions",
                                                "summary": "It is a long established fact that a reader will be distracted by the readable"
                                            }
                                        ].map((item, index) => (

                                            <div className="email-item" key={index}>
                                                <div className="avatar">
                                                    {item.avatar && <img src={item.avatar} alt="" />}
                                                    {item.initial && <span className={"avatar-initial bg-" + item.initial.bg}>{item.initial.letter}</span>}
                                                </div>
                                                <div className="email-item-body">
                                                    <div className="d-flex align-items-center mb-1">
                                                        <span className="email-item-sender">{item.sender}</span>
                                                        <span className="email-item-date">{item.time}</span>
                                                    </div>
                                                    <h6 className="email-item-subject">{item.subject}</h6>
                                                    <p className="email-item-summary">{item.summary}</p>
                                                </div>
                                            </div>
                                        ))}

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
};