import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import ViewlistModal from "./pages/view-list";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOpenCasesList } from "../../../application/selector/indexSelector";
import { GetOpenCasesList } from "../../../application/action/auditAction";
import EmptyState from "../../_common/order-empty";

export default function PendingAudit() {
    const dispatch = useDispatch();
    const OpenCases = useSelector(getOpenCasesList)
    useEffect(() => {
        dispatch(GetOpenCasesList())
    }, [])
    const [showViewlistModal, setShowViewlistModal] = useState(false);
    const handleViewlistModal = (state) => {
        setShowViewlistModal(state);
    }

    const navigate = useNavigate();

    const goStartAudit = () => {
        navigate("/start-audit");
    };
    return (
        <React.Fragment>
            <ViewlistModal show={showViewlistModal} closeFunction={handleViewlistModal} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16 mb-1">Pending Audit</h4>
                    <h6 className="fs-14 text-black-50">Project wise pending cases to be audited</h6>
                </div>
            </div>
            {OpenCases && OpenCases.length !== 0 ? (
            <Row className="row-cols-auto g-3 g-xl-4 pt-2">
                {OpenCases?.map((data, index) => (
                    <Col lg={3} md={3} sm={6} key={index}>
                        <Card className="card-one">
                            <Card.Body>
                                <h2 className="mb-2 fs-24">
                                    <span className="fw-semibold">{data.cases_Count}</span> 
                                    <span className="fs-14">Open for Audit</span>
                                </h2>
                                <h3 className="fs-16 mb-4">{data.projectName}</h3>
                                <div className="d-flex gap-2">
                                    <Button variant="primary" className="flex-fill" onClick={goStartAudit}>Start Audit</Button>
                                    <Button variant="secondary" className="flex-fill" onClick={() => handleViewlistModal(true)}>View List</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
             ) : (
                <>
                  <EmptyState />
                </>
              )}

        </React.Fragment>
    )
}