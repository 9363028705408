import { GET_ALL_AUDITOR_SUCCESS, GET_ALL_ROLES_SUCCESS, GET_ALL_SUPERVISOR_SUCCESS, GET_ALL_USERGROUPS_SUCCESS, GET_USERS_MAPPING_LIST_SUCCESS } from "../action/userAction";

const initialState = {
    UsersMappingList: undefined,
    AuditorList: undefined,
    RolesList:undefined,
    SupervisorList: undefined,
    UserGroupList: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_MAPPING_LIST_SUCCESS:
            return { ...state, UsersMappingList: action.payload.data, error: null };
        case GET_ALL_SUPERVISOR_SUCCESS:
            return { ...state, SupervisorList: action.payload.data, error: null };
        case GET_ALL_AUDITOR_SUCCESS:
            return { ...state, AuditorList: action.payload.data, error: null };
        case GET_ALL_ROLES_SUCCESS:
            return { ...state, RolesList: action.payload.data, error: null };
        case GET_ALL_USERGROUPS_SUCCESS:
            return { ...state,UserGroupList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;