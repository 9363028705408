import React, { useState } from "react";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { Bar } from "react-chartjs-2";
import { Card, Col, Row, Form } from "react-bootstrap";
import auditorListData from "../../Json/Dashboard/auditor-dashboard.json";
import TableShimmerLoader from "../_common/ShimmerTable";
import { useSelector } from "react-redux";
import { getLoading } from "../../application/selector/indexSelector";

export default function AuditorDashboard() {
    const { loading, value } = useSelector(getLoading);
    const auditor_status_bar = {
        labels: ['Pending Audit', 'Ongoing Audit', 'Complete Audit'],
        datasets: [{
            data: [95, 125, 220],
            backgroundColor: ['#dc3545', '#506fd9', '#0cb785'],
            barPercentage: 0.5
        }]
    };

    const auditor_status_optionBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 400,
                ticks: {
                    color: '#a1aab3',
                    font: {
                        size: 10
                    }
                }
            }
        }
    };

    // TABLE COLUMNS
    const columns = [
        {
            name: "Auditor Name",
            selector: (row) => row.auditor_name,
            sortable: true,
        },
        {
            name: "Audit Date",
            selector: (row) => row.audit_date,
            sortable: true,
        },
        {
            name: "Audit Type",
            selector: (row) => row.audit_type,
            sortable: true,
        },
        {
            name: "Project Name",
            selector: (row) => row.project_name,
            sortable: true,
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredAuditorList = auditorListData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title fs-16 mb-0">Auditor Dashboard</h4>
                    </div>
                </div>

                <Row className="g-3">
                    {[
                        {
                            "bg": "primary",
                            "icon": "ri-user-search-line",
                            "value": "220",
                            "label": "Total Audit",
                        }, {
                            "bg": "success",
                            "icon": "ri-checkbox-circle-line",
                            "value": "125",
                            "label": "Complete Audit",
                        }, {
                            "bg": "danger",
                            "icon": "ri-time-line",
                            "value": "95",
                            "label": "Pending Audit",
                        }
                    ].map((item, index) => (
                        <Col sm="4" key={index}>
                            <Card className="card-one">
                                <Card.Body className="p-3">
                                    <div className="d-flex d-sm-block d-xl-flex align-items-center">
                                        <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                                            <h2 className="card-value d-flex align-items-baseline mb-0">{item.value}</h2>
                                            <label className="card-label fs-sm fw-medium text-uppercase">{item.label}</label>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}


                    <Col md={8}>
                        <Card className="card-one">
                            <Card.Header className="align-items-center justify-content-between ht-60">
                                <Card.Title as="h6">Auditor List</Card.Title>
                                <div className="custom-dropdown-wrapper">
                                    <div className="custom-drop-down z-index-2 wt-300">
                                        <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                        <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {filteredAuditorList && filteredAuditorList !== undefined && (
                                    <DataTable
                                        columns={columns}
                                        data={filteredAuditorList}
                                        fixedHeader
                                        search={true}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={5}
                                        paginationRowsPerPageOptions={[]}  // Hides the "rows per page" dropdown
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,  // Removes rows per page text
                                        }}
                                    ></DataTable>
                                )}
                        {!loading && filteredAuditorList === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "AuditList" && filteredAuditorList === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="card-one">
                            <Card.Header className="ht-60">
                                <Card.Title as="h6">Auditor Status</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <Bar className="ht-300" data={auditor_status_bar} options={auditor_status_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
};