import React from "react";

export default function CaseData() {
    return (
        <React.Fragment>
            <ul className="list-group list-group-flush">
                {[
                    {
                        "field_name": "Field 1",
                        "field_data": "Sample Data Here",
                    },
                    {
                        "field_name": "Field 2",
                        "field_data": "Sample Data Here"
                    },
                    {
                        "field_name": "Field 3",
                        "field_data": "Sample Data Here"
                    },
                    {
                        "field_name": "Field 4",
                        "field_data": "Sample Data Here"
                    },
                    {
                        "field_name": "Field 5",
                        "field_data": "Sample Data Here"
                    },
                    {
                        "field_name": "Field 6",
                        "field_data": "Sample Data Here"
                    }].map((item, index) => (
                        <li className="list-group-item d-flex justify-content-between" key={index}>
                            <span className="fs-14">{item.field_name}</span>
                            <span className="fs-14">{item.field_data}</span>
                        </li>
                    ))}
            </ul>
        </React.Fragment>
    )
}