
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Card, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getErrorCategoryList, getErrorList, getMapAuditFormErrorsList } from "../../../application/selector/indexSelector";
import useAuth from '../../../hooks/useAuth';
import { MapAuditFormError, UnMapAuditFormError } from "../../../application/action/projectAction";
import Environment from "../../../infrastructure/core/Environment";

export default function AuditErrors(props) {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const ErrorList = useSelector(getErrorList);
    const ErrorCategoryList = useSelector(getErrorCategoryList);
    const MappedErrors = useSelector(getMapAuditFormErrorsList);
    const [selectedError, setSelectedError] = useState(null);
    const [addedErrors, setAddedErrors] = useState([]);
    const [selectedErrorCategory, setSelectedErrorCategory] = useState(null);
    const initialFormData = {
        formId: props?.data?.id,
        createdBy: auth.id,
        modifiedBy: auth.id,
        errorsArray: [], // Holds all errors
    };
    // This function filters out the already selected errors from the errorsOption
    const getAvailableErrorsOptions = () => {
        const selectedErrorIds = addedErrors.map(error => error.errorId); // Get all selected error IDs
        return ErrorList
            ?.filter(err => !selectedErrorIds.includes(err.id)) // Filter out selected errors
            .map(err => ({
                value: err.id,
                label: err.errorName
            }));
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (MappedErrors && MappedErrors.length > 0) {
            setAddedErrors(MappedErrors);
        }
    }, [MappedErrors]);


    const errorsOption = ErrorList?.map((err) => ({
        value: err.id,
        label: err.errorName
    }));
    const ErrorCategoryOption = ErrorCategoryList?.map((err) => ({
        value: err.id,
        label: err.errorCategoryName
    }));



    // Add a new error entry to the table and formData.errorsArray
    const addErrorToTable = () => {
        const newErrorEntry = {
            ...formData,
            errorId: selectedError?.value,
            errorCategoryId: selectedErrorCategory?.value,
        };
        setAddedErrors([...addedErrors, newErrorEntry]);
        // Clear selected fields after adding
        setSelectedError(null);
        setSelectedErrorCategory(null);

        // setFormData(updatedFormData); // Update formData with the new array
    };

    const handleSelectionChange = (index, field, value) => {
        const updatedErrors = [...addedErrors];

        // Update the specific error in addedErrors
        updatedErrors[index] = { ...updatedErrors[index], [field]: value };
        setAddedErrors(updatedErrors);

        // Create a copy of the existing errorsArray
        const updatedErrorsArray = [...formData.errorsArray];

        // Find the index of the modified error in formData.errorsArray using the errorId
        const errorIndexInFormData = updatedErrorsArray.findIndex(
            error => error.errorId === updatedErrors[index].errorId
        );
        if (errorIndexInFormData !== -1) {
            // If the error exists, update it in errorsArray
            updatedErrorsArray[errorIndexInFormData] = updatedErrors[index];
        } else {
            // If the error doesn't exist, add it to errorsArray
            updatedErrorsArray.push(updatedErrors[index]);
        }

        // Update formData with the modified errorsArray
        setFormData(prevFormData => ({
            ...prevFormData, [field]: value,
            errorsArray: updatedErrorsArray,
        }));
    };

    console.log('formData', formData);

    const removeError = (index, data) => {
        const updatedErrors = addedErrors.filter((_, idx) => idx !== index);
        setAddedErrors(updatedErrors);
        setFormData({
            ...formData,
            errorsArray: updatedErrors,
        });
        debugger
        if (data.id !== undefined) {
            const Fomrmdata = {
                Id: data.id,
                formId: data.formId
            }
            dispatch(UnMapAuditFormError(Fomrmdata))
        }
    };

    const handleUpdateError = (e) => {
        e.preventDefault();
        const formattedData = formData.errorsArray.map(data => ({
            "mappingId": data.id || Environment.defaultValue,
            "errorId": data.errorId,
            "errorCategoryId": data.errorCategoryId,
            "formId": formData.formId,
            "isFatal":  data.isFatal === true || data.isFatal === "true",
            "isMapToScore":data.isMapToScore === true || data.isMapToScore === "true",
            "createdBy": formData.createdBy,
            "modifiedBy": formData.modifiedBy
        }));
        console.log('formattedData', formattedData);

        dispatch(MapAuditFormError(formattedData));
        setFormData(initialFormData)
    };

    return (
        <React.Fragment>
            <Card className="card-one mb-4">
                <Card.Header className="justify-content-between">
                    <h3 className="fs-16">Errors</h3>
                    <Button type="button" variant="dark" onClick={addErrorToTable}>ADD</Button>
                </Card.Header>
                <Form>
                    <Card.Body>
                        <h3 className="fs-14 mb-3">Add error opportunities</h3>
                        <div className="common-table mb-4">
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Errors</th>
                                        <th>Map to Score</th>
                                        <th>Is Fatal</th>
                                        <th>Category</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedErrors.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" className="text-center">No errors added</td>
                                        </tr>
                                    ) : (
                                        addedErrors.map((error, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Select
                                                        className="wt-300"
                                                        options={getAvailableErrorsOptions()}
                                                        value={errorsOption.find(e => e.value === error.errorId)}
                                                        onChange={(selected) => handleSelectionChange(index, 'errorId', selected.value)}
                                                    />

                                                </td>
                                                <td>
                                                    <Form.Select
                                                        className="wt-200"
                                                        value={error.isMapToScore}
                                                        onChange={(e) => handleSelectionChange(index, 'isMapToScore', e.target.value)}
                                                    >
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        className="wt-200"
                                                        value={error.isFatal}
                                                        onChange={(e) => handleSelectionChange(index, 'isFatal', e.target.value)}
                                                    >
                                                        <option value={true}>Fatal</option>
                                                        <option value={false}>Non Fatal</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Select
                                                        className="wt-300"
                                                        options={ErrorCategoryOption}
                                                        value={ErrorCategoryOption?.find(e => e.value === error.errorCategoryId)}
                                                        onChange={(selected) => handleSelectionChange(index, 'errorCategoryId', selected.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <Button variant="outline-danger" onClick={() => removeError(index, error)}>
                                                        <i className="ri-close-line"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <Button type="button" variant="dark" onClick={handleUpdateError}>Submit</Button>
                    </Card.Footer>
                </Form>
            </Card>
        </React.Fragment>
    );
}
