import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Table } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { getHeaderList, getInventoryDataList, getLoading } from "../../application/selector/indexSelector";
import TableShimmerLoader from "../_common/ShimmerTable";

export default function PreviewInventoryData({ closeFunction, show }) {
    const { loading, value } = useSelector(getLoading);
    const [previewData, setPreviewData] = useState([]);
    const { auth } = useAuth();
    const InventoryData = useSelector(getInventoryDataList);
    const headerMapping = useSelector(getHeaderList);

    useEffect(() => {
        if (Array.isArray(InventoryData)) {
            setPreviewData(InventoryData);
        } else {
            setPreviewData([]); // Reset to an empty array if data is invalid
        }
    }, [InventoryData]);
    // Define the mapping of column names to header values
    const headerMap = headerMapping?.reduce((map, header) => {
        map[header.headerId] = header.headerValue;
        return map;
    }, {});

    // Get table headers (keys from the first object) and exclude specific columns
    const excludedColumns = ["Id", "InventoryId", "AddedBy", "AddedOn"];
    const tableHeaders = previewData.length > 0
        ? Object.keys(previewData[0]).filter(header => !excludedColumns.includes(header))
        : [];

    // Map column names to header names
    const mappedTableHeaders = tableHeaders.map(header => headerMap[header] || header);


    return (
        <React.Fragment>
            <Offcanvas show={show} onHide={closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Inventory Excel View</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    {previewData && Array.isArray(previewData) && previewData.length > 0 ? (
                        <Table className="mb-0" striped bordered hover>
                            <thead>
                                <tr>
                                    {mappedTableHeaders.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {previewData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {tableHeaders.map((header, cellIndex) => (
                                            <td key={cellIndex}>{row[header]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : !loading && previewData === undefined ? (
                        <TableShimmerLoader colNames={[]} colsCount={mappedTableHeaders.length || 1} />
                    ) : (
                        loading &&
                        value === "DataSourceList" &&
                        previewData === undefined && (
                            <TableShimmerLoader colNames={[]} colsCount={mappedTableHeaders.length || 1} />
                        )
                    )}

                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-end gap-2">
                    <Button
                        type="button"
                        variant="secondary"
                        className="fs-14 me-2 d-flex align-items-center"
                        onClick={() => closeFunction()}
                    >
                        <span className="align-middle">Close</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
