import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Offcanvas, Form, Card, Button } from "react-bootstrap";
import listViewData from "../../../../Json/QAAudit/view-list.json";
import { useNavigate } from "react-router-dom";
import TableShimmerLoader from "../../../_common/ShimmerTable";
import { useSelector } from "react-redux";
import { getLoading } from "../../../../application/selector/indexSelector";

export default function ViewlistModal(props) {
    const { loading, value } = useSelector(getLoading);
    const columns = [
        {
            name: "Case ID",
            selector: (row) => row.lmis_case_id,
            sortable: true,
        },
        {
            name: "Agent Name",
            selector: (row) => row.agent_name,
            sortable: true,
        },
        {
            name: "Team Name",
            selector: (row) => row.lmis_case_id,
            sortable: true,
        },
        {
            name: "Case Date",
            selector: (row) => row.case_date,
            sortable: true,
        },
        {
            name: "Start Audit",
            selector: () => (
                <Button variant="primary" className="text-uppercase fs-12" onClick={goStartAudit}>Start Audit</Button>
            ),
            sortable: true,
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = listViewData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const navigate = useNavigate();
    const goStartAudit = () => {
        navigate("/start-audit");
      };

    return (
        <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-60">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">
                    View List
                </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Card className="card-one">
                <Card.Header className="align-items-center justify-content-end d-flex">
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                    <Card.Body>
                    {filteredEmployees && filteredEmployees !== undefined && (
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "ViewList" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                    </Card.Body>
                </Card>
            </Offcanvas.Body>
        </Offcanvas>
    )
}