import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ErrorType from "./ErrorType/error-type";
import ErrorCategories from "./ErrorCategories/error-categories";
import { useDispatch, useSelector } from "react-redux";
import { ErrorCategoryList, ErrorList } from "../../../application/action/errorAction";


export default function ErrorMaster() {
    const [activeTab, setActiveTab] = useState('error_type_tab');
    const dispatch = useDispatch();

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
    useEffect(()=>{
        dispatch(ErrorList())
        dispatch(ErrorCategoryList())
    },[])
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Error Master</h4>
                </div>
            </div>

            <div className="common-tabs">
                <Card className="card-one">
                    <Card.Body>
                        <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                            <Tab eventKey="error_type_tab" title="Error Type" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <ErrorType />
                                </div>
                            </Tab>
                            <Tab eventKey="error_categories_tab" title="Error Categories" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <ErrorCategories />
                                </div>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}