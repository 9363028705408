import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { ADD_DATASOURCE, DataSourceListSuccess, GET_ALL_HEADER, GET_DATASOURCE_LIST, GET_INVENTORY_DATA_LIST_BYID, GET_INVENTORY_LIST, GetAllHeaders, GetAllHeadersSuccess, GetDataSourceList, GetInventoryDataListByIdSuccess, GetInventoryList, InventoryListSuccess, UPLOAD_INVENTORY } from "../action/inventoryAction";


const InventoryList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVENTORY_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "inventoryList" }));
        const inventoryList = await api.inventoryAPI.InventoryList();
        
        dispatch(InventoryListSuccess(inventoryList));
        dispatch(Loading.setLoading({ loading: false, value: "inventoryList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UploadInventory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPLOAD_INVENTORY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "inventoryUpload" }));
        await api.inventoryAPI.UploadInventory(action.payload);
        dispatch(GetInventoryList());
        dispatch(GetDataSourceList());
        dispatch(Loading.setLoading({ loading: false, value: "inventoryUpload" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const DataSourceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_DATASOURCE_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "dataSourceList" }));
        const dataSourceList = await api.inventoryAPI.DataSourceList(action.payload);
        
        dispatch(DataSourceListSuccess(dataSourceList));
        dispatch(Loading.setLoading({ loading: false, value: "dataSourceList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const AddDatasource =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_DATASOURCE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AddSource" }));
       const  dataSourceList = await api.inventoryAPI.AddDatasource(action.payload);
        dispatch(DataSourceListSuccess(dataSourceList));
        dispatch(Loading.setLoading({ loading: false, value: "AddSource" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const InventoryDataListById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVENTORY_DATA_LIST_BYID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "InventoryDataList" }));
       const  InventoryDataList = await api.inventoryAPI.InventoryDataListById(action.payload);
        dispatch(GetInventoryDataListByIdSuccess(InventoryDataList));
        dispatch(Loading.setLoading({ loading: false, value: "InventoryDataList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const AllHeaders =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_HEADER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "HeadersList" }));
       const  HeadersList = await api.inventoryAPI.AllHeaders(action.payload);
        dispatch(GetAllHeadersSuccess(HeadersList));
        dispatch(Loading.setLoading({ loading: false, value: "HeadersList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
    InventoryList,DataSourceList,UploadInventory,AddDatasource,InventoryDataListById,AllHeaders
  ]