import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_TEAMS_LIST, TeamsListSuccess } from "../action/TeamsAction";


const TeamsList = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === GET_TEAMS_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "teamsList" }));
        const teamsList = await api.teamsAPI.TeamsList(action.payload);
        
        dispatch(TeamsListSuccess(teamsList));
        dispatch(Loading.setLoading({ loading: false, value: "teamsList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [
    TeamsList
  ]