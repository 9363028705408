export const GET_ERROR_LIST = 'GET_ERROR_LIST';
export const ERROR_UPLOAD = 'ERROR_UPLOAD';
export const ERROR_CATEGORY_UPLOAD = 'ERROR_CATEGORY_UPLOAD';
export const GET_ERROR_LIST_SUCCESS = 'GET_ERROR_LIST_SUCCESS';
export const GET_ERROR_CATEGORY_LIST = 'GET_ERROR_CATEGORY_LIST';
export const GET_ERROR_CATEGORY_SUCCESS = 'GET_ERROR_CATEGORY_SUCCESS';

export const ErrorUpload = (data) => ({
    type:ERROR_UPLOAD,
    payload:data
});
export const ErrorList = (data) => ({
    type: GET_ERROR_LIST,
    payload:data
});
export const ErrorListSuccess = (data) => ({
    type: GET_ERROR_LIST_SUCCESS,
    payload:data
});
export const ErrorCategoryList = (data) => ({
    type: GET_ERROR_CATEGORY_LIST,
    payload:data
});
export const ErrorCategoryUpload = (data) => ({
    type:ERROR_CATEGORY_UPLOAD,
    payload:data
});
export const ErrorCategoryListSuccess = (data) => ({
    type: GET_ERROR_CATEGORY_SUCCESS,
    payload:data
});