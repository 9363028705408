import React, { useRef } from "react";
import Header from "../../layouts/Header";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

export default function Reports() {
    const fileInputRef = useRef(null);
    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };

    const handleExportData = (event) => {

    };
    const agentNameOption = [
        { value: "1", label: "Dhiraj Sanjay Parmar" },
        { value: "2", label: "Jason Rajendra Joseph" },
        { value: "3", label: "Naphidahun Marngar" },
        { value: "4", label: "Neha Ashok Sao" },
        { value: "5", label: "Lakhvinder Singh Nirmal Singh Dhariwal" },
        { value: "6", label: "Rasheedah Outlaw" },
    ];

    const projectNameOption = [
        { value: "1", label: "IVT-CGS" },
        { value: "2", label: "IVT-Natera" },
        { value: "3", label: "PRIOR AUTH - CANCELLATIONS" },
        { value: "4", label: "PRIOR AUTH - DECISION - VOICE" },
        { value: "5", label: "DENIALS-CGS" },
        { value: "6", label: "CDS-CGS" },
    ];

    const auditorNameOption = [
        { value: "1", label: "Deepak Sharma" },
        { value: "2", label: "Dhiraj Sanjay Parmar" },
        { value: "3", label: "Jason Rajendra Joseph" },
        { value: "4", label: "Naphidahun Marngar" },
        { value: "5", label: "Neha Ashok Sao" }
    ];
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Report</h4>
                    </div>
                    <div>
                        <Form.Control type="file" ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleExportData} />
                        <Button variant="dark" className="fs-14 text-nowrap gap-2" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line fs-18 me-2 align-middle"></i>
                            <span className="align-middle">Export Data</span>
                        </Button>
                    </div>
                </div>

                <Card className="card-one">
                <Card.Body>
                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">Report Name:</Form.Label>
                        </Col>
                        <Col md={3}>
                            <Form.Control type="text" placeholder="Enter Report Name" />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">Agent Name:</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Select options={agentNameOption} isSearchable={true} placeholder="Select Agent Name" />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">Project Name:</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Select options={projectNameOption} isSearchable={true} placeholder="Select Project Name" />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">Auditor:</Form.Label>
                        </Col>
                        <Col md={4}>
                            <Select options={auditorNameOption} isSearchable={true} placeholder="Select Auditor" />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">From Date:</Form.Label>
                        </Col>

                        <Col md={3}>
                            <Form.Control type="date" />
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-4">
                        <Col md={3}>
                            <Form.Label className="mb-0 text-right d-block">To Date:</Form.Label>
                        </Col>

                        <Col md={3}>
                            <Form.Control type="date" />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            </div>
        </React.Fragment>
    )
}