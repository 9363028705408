import React, { useEffect } from "react";
import Prism from "prismjs";
import "./order-empty.scss"
import emptyStateImg from "../../assets/img/no-results.png";

export default function EmptyState() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);


    return (
        <React.Fragment>
            <div className="no-order-state">
                <div>
                    <img src={emptyStateImg} alt="Empty State" />
                </div>
                <h3 className="fs-16 fw-semibold text-dark mt-3 text-uppercase opacity-75">Nothing In Here.</h3>
            </div>
        </React.Fragment>
    );
}
