import React from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";

export default function UpdateUser(props) {

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update User</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Agent Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Agent Name" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>LMIS Case ID</Form.Label>
                                <Form.Control type="text" placeholder="Enter LMIS Case ID" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Project Name" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-4">
                                <Form.Label>Audit Date</Form.Label>
                                <Form.Control type="date" placeholder="Enter Audit Date" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Comment" />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Update User</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )

};