import React from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";

export default function UpdatePendingResponse(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Pending Response</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col lg={8}>
                            <div className="mb-4">
                                <Form.Label>Agent Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Agent Name" />
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className="mb-4">
                                <Form.Label>Lmis Case ID</Form.Label>
                                <Form.Control type="text" placeholder="Enter Lmis Case ID" />
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className="mb-4">
                                <Form.Label>Audit Date</Form.Label>
                                <Form.Control type="text" placeholder="Enter Audit Date" />
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className="mb-4">
                                <Form.Label>Audit Status</Form.Label>
                                <Form.Select>
                                    <option>Choose Audit Satus</option>
                                    <option>Open</option>
                                    <option>Pending TL Response</option>
                                    <option>TL Rebuutal</option>
                                    <option>Closed</option>
                                </Form.Select>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className="mb-4">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control as="textarea" placeholder="Enter Comment" rows={4} />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
};