import React from "react";
import Header from "../../layouts/Header";
import { Card, Col, Row } from "react-bootstrap";

export default function Dashboard() {
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Dashboard</h4>
                    </div>
                </div>


                <Row className="g-3">
                    {[
                        {
                            "label": "Total Client",
                            "icon": "ri-shopping-bag-3-line",
                            "value": "135",
                            "percent": "0.7",
                            "status": "down"
                        }, {
                            "label": "Total Project",
                            "icon": "ri-briefcase-4-line",
                            "value": "550",
                            "percent": "2.1",
                            "status": "up"
                        }, {
                            "label": "Total Auditor",
                            "icon": "ri-inbox-line",
                            "value": "300",
                            "percent": "0.3",
                            "status": "down"
                        }, {
                            "label": "Total Errors",
                            "icon": "ri-bar-chart-box-line",
                            "value": "120",
                            "percent": "1.2",
                            "status": "up"
                        }
                    ].map((card, index) => (
                        <Col xs="6" xl="3" key={index}>
                            <Card className="card-one">
                                <Card.Body>
                                    <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                                    <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                                    <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </React.Fragment>
    )
}