import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {AddUserGroup} from "../../../application/action/userAction"
export default function CreateUserGroup(props) {
  const dispatch = useDispatch();
  const { setAuth, auth } = useAuth();
  const initialState = {
    userGroupName: "",
    createdBy: auth.id,
    modifiedBy: auth.id,
  };
  const [formData, setFormData] = useState(initialState);
   // Handle input change
   const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit =(e)=>{
    e.preventDefault();
    dispatch(AddUserGroup(formData))
    setFormData(initialState)
    props.closeFunction()
  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New User Group
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>User Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter User Group Name"
                  name="userGroupName"
                  value={formData.userGroupName}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create User Group Name</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
