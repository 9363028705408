import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    DashboardMenu,
    PendingResponseMenu,
    ReportAnayticsMenu,
    InventoryMenu,
    ProjectsMenu,
    AuditFormMenu,
    ReportMenu,
    SystemSettingMenu
} from "../data/Menu";
import { Badge, Image } from "react-bootstrap";
import logo from "../assets/img/logo/main-logo.png";

export default class Sidebar extends Component {
    toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-header">
                    <Link to="/" className="sidebar-logo">
                        <Image src={logo} className="wt-200 dark-mode-logo" />
                    </Link>
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
                </PerfectScrollbar>
                <div className="sidebar-footer bottom-0">
                    {/* <div className="sidebar-footer-top">
                        <div className="sidebar-footer-thumb">
                            <img src={userAvatar} alt="" />
                        </div>
                        <div className="sidebar-footer-body">
                            <h6><Link to="../pages/profile.html">Aamir Sayyed</Link></h6>
                            <p>AR Audit Tool</p>
                        </div>
                        <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                    </div>
                    <div className="sidebar-footer-menu">
                        <nav className="nav">
                            <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
                            <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
                        </nav>
                        <hr />
                        <nav className="nav">
                            <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                            <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                            <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
                            <Link to="/account/login"><i className="ri-logout-box-r-line"></i> Log Out</Link>
                        </nav>
                    </div> */}
                </div>
            </div>
        )
    }
}

class SidebarMenu extends Component {
    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link position-relative">
                            <i className={m.icon}></i>
                            <span>{m.label}</span>
                            <Badge bg="danger" pill className="position-absolute end-0">{m.count}</Badge>
                        </NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    render() {
        return (
            <React.Fragment>

                <div className="nav-group">
                    <div className="nav-label text-dark fs-14 fw-semibold" onClick={this.toggleMenu}>Dashboard</div>
                    {this.populateMenu(DashboardMenu)}
                </div>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(ReportAnayticsMenu)}
                </div>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(PendingResponseMenu)}
                </div>

                <h5 className="fw-bold text-dark fs-14 mt-3 mb-3 ml-1" style={{ marginLeft: '20px' }}>MANAGE</h5>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(InventoryMenu)}
                </div>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(ProjectsMenu)}
                </div>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(AuditFormMenu)}
                </div>
               
                <div className="nav-group show without-dropdown">
                    {this.populateMenu(ReportMenu)}
                </div>

                <div className="nav-group show without-dropdown">
                    {this.populateMenu(SystemSettingMenu)}
                </div>
            </React.Fragment>
        )
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});