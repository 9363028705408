import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_OPENCASES_LIST, GetOpenCasesListSuccess } from "../action/auditAction";


const OpenCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_OPENCASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "openCasesList" }));
        const openCasesList = await api.auditAPI.OpenCasesList(action.payload);
        
        dispatch(GetOpenCasesListSuccess(openCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "openCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  export default [
    OpenCasesList
  ]