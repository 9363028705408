
import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const InventoryList = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.INVENTORY_URL}getInventoryLog`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const DataSourceList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.INVENTORY_URL}datasource`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UploadInventory = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.INVENTORY_URL}addInventory`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};

const AddDatasource = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.INVENTORY_URL}addDataSouorce`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const InventoryDataListById = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.INVENTORY_URL}getInventoryDataById/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const AllHeaders = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.INVENTORY_URL}GetAllHeaders`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    InventoryList,DataSourceList,UploadInventory,AddDatasource,InventoryDataListById,AllHeaders
  }