import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { ADD_USER, GET_ALL_AUDITOR, GET_ALL_ROLES, GET_ALL_SUPERVISOR, GET_ALL_USERGROUPS,ADD_USER_GROUP, GET_USERS_MAPPING_LIST, GET_USERS_MAPPING_LIST_SUCCESS, GetAllAuditorsSuccess, GetAllRolesSuccess, GetAllSupervisorSuccess, UPLOAD_BRIDGE_DATA, UsersMappingList, UsersMappingListSuccess ,GetAllUserGroupsSuccess} from "../action/userAction";


const UserMappingList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_USERS_MAPPING_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
        const usersMappingList = await api.userAPI.UsersMappingList();
        
        dispatch(UsersMappingListSuccess(usersMappingList));
        dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UploadBridgeData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPLOAD_BRIDGE_DATA) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
        const usersMappingList = await api.userAPI.UploadBridgeData(action.payload);
        dispatch(UsersMappingListSuccess(usersMappingList));
        dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAllUserByRoleId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_SUPERVISOR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "supervisorList" }));
        const supervisorList = await api.userAPI.GetAllUserByRoleId(action.payload);
        dispatch(GetAllSupervisorSuccess(supervisorList));
        dispatch(Loading.setLoading({ loading: false, value: "supervisorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }else if (action.type === GET_ALL_AUDITOR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditorList" }));
        
        const auditorList = await api.userAPI.GetAllUserByRoleId(action.payload);
        dispatch(GetAllAuditorsSuccess(auditorList));
        dispatch(Loading.setLoading({ loading: false, value: "auditorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetAllRoles =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_ROLES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "rolesList" }));
        const rolesList = await api.userAPI.GetAllRoles(action.payload);
        dispatch(GetAllRolesSuccess(rolesList));
        dispatch(Loading.setLoading({ loading: false, value: "rolesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  }; 
  const AddUser =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_USER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
       await api.userAPI.AddUser(action.payload);
        dispatch(UsersMappingList());
        dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddUserGroup =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_USER_GROUP) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "usersmappingList" }));
       const userGroupList=await api.userAPI.AddUserGroup(action.payload);
        dispatch(GetAllUserGroupsSuccess(userGroupList));
        dispatch(Loading.setLoading({ loading: false, value: "usersmappingList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetAllUserGroups =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_USERGROUPS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "rolesList" }));
        const UserGroupsList = await api.userAPI.GetAllUserGroups(action.payload);
        dispatch(GetAllUserGroupsSuccess(UserGroupsList));
        dispatch(Loading.setLoading({ loading: false, value: "rolesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  }; 

export default [
    UserMappingList,UploadBridgeData,GetAllUserByRoleId,GetAllRoles,AddUser,GetAllUserGroups,AddUserGroup
  ]