import axios from "axios";

//const BASE_URL_HOSTED = 'https://araudit.cxengine.net/';
const BASE_URL_HOSTED = 'https://arauditgateway.cxengine.net/';
const BASE_URL_LOCAL = 'https://localhost:7284/api/'
const mode = '';
export default axios.create({
    baseURL: mode==='dev'?BASE_URL_LOCAL:BASE_URL_HOSTED
});

export const axiosPrivate = axios.create({
    baseURL: mode==='dev'?BASE_URL_LOCAL:BASE_URL_HOSTED,
    headers: { 'Content-Type': 'application/json' },
   // withCredentials: true
});