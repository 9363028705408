export const getLoading = (state) => state.loader;

//Teams
export const getTeamsList = (state) => state.teams.TeamsList
// User
export const getUsersMappingList = (state) => state.user.UsersMappingList
export const getUserGroupList = (state) => state.user.UserGroupList
export const getSupervisorList = (state) => state.user.SupervisorList
export const getAuditorList = (state) => state.user.AuditorList
export const getRolesList = (state) => state.user.RolesList

// Errors
export const getErrorList = (state) => state.error.ErrorList
export const getErrorCategoryList = (state) => state.error.ErrorCategoryList

export const getPendingResponse = (state) => state.pendingResponse.PendingResponseList

//Inventory
export const getInventoryList = (state) => state.inventory.InventoryList
export const getDataSourceList = (state) => state.inventory.DataSourceList
export const getInventoryDataList = (state) => state.inventory.InventoryDataList
export const getHeaderList = (state) => state.inventory.HeaderList

//project

export const getProjectList = (state) => state.project.ProjectList
export const getMapProjectAuditorList = (state) => state.project.MapProjectAuditorList
export const getProjectAuditFormListById = (state) => state.project.ProjectAuditFormListById
export const getMapProjectAuditFormList = (state) => state.project.MapProjectAuditFormList
export const getMapProjectColumnList = (state) => state.project.MapProjectColumnList


// audit
export const getAuditFormList = (state) => state.project.AuditFormList
export const getMapAuditFormErrorsList = (state) => state.project.MapAuditFormErrorsList
export const getMapAuditFormColumnList = (state) => state.project.MapAuditFormColumnList
export const getOpenCasesList = (state) => state.audit.OpenCasesList
export const getMapProjectList = (state) => state.project.MapProjectList
