import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import OpenAuditComponent from "./OpenAudit/open-audits";
import CloseAuditComponent from "./CloseAudit/close-audits";

export default function MyAudits() {
    const [isSidebarShow, setSidebarShow] = useState(false);
    return (
        <React.Fragment>
            <Header />
            <Tab.Container id="left-tabs-example" defaultActiveKey="open_audits_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <div className="p-2 pt-0 pb-0 mb-3 mt-3">
                            <Button variant="primary" className="w-100">
                                <i className="ri-add-line align-middle"></i> <span className="align-middle">Start New</span>
                            </Button>
                        </div>
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="open_audits_tab"><i className="ri-archive-line"></i> Open Audits</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="close_audits_tab"><i className="ri-contacts-fill"></i> Close Audit</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>

                        <Tab.Content>
                            {/* OPEN AUDIT TAB */}
                            <Tab.Pane eventKey="open_audits_tab"><OpenAuditComponent /></Tab.Pane>

                            {/* CLOSE AUDITS TAB */}
                            <Tab.Pane eventKey="close_audits_tab"><CloseAuditComponent /></Tab.Pane>

                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}