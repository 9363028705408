import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Offcanvas, Form, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDataSourceList, getHeaderList, getLoading, getTeamsList } from "../../../application/selector/indexSelector";
import useAuth from "../../../hooks/useAuth";
import Environment from "../../../infrastructure/core/Environment";
import { AddProject } from "../../../application/action/projectAction";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function CreateProject(props) {
    const teamsList = useSelector(getTeamsList)
    const columnsList = useSelector(getHeaderList)
    const datasourceList = useSelector(getDataSourceList)
    const dispatch = useDispatch()
    const loading = useSelector(getLoading)

    const { auth } = useAuth();
    const initialState = {
        projectName: "",
        projectDescription: "",
        teamId: Environment.defaultValue,
        sourceId: Environment.defaultValue,
        empId: "",
        createdBy: auth.id,
        modifiedBy: auth.id
    }
    const [formData, setFormData] = useState(initialState);
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleTeam = (event) => {
        const selectedTeamId = event.target.value;
        const selectedTeam = teamsList.find(team => team.id === selectedTeamId); // Find the selected team based on ID

        if (selectedTeam) {
            setFormData({
                ...formData,
                teamId: selectedTeam.id, // Update team ID
                projectName: selectedTeam.teamName // Update project name
            });
        }
    };
    const handleSubmit =(e)=>{
        e.preventDefault()
        dispatch(AddProject(formData))

    }
    useEffect(() => {
        if (loading.value == 'projectList' && !loading.loading) {
            props.closeFunction()
            setFormData(initialState)
        }
    }, [loading])
    return (
        <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">Create New Project</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Project Team</Form.Label>
                            <Form.Select className="wt-400" value={formData.teamId} onChange={handleTeam}>
                                <option value="">Select Team</option>
                                {teamsList?.map((team) => (
                                    <option key={team.id} value={team.id}>
                                        {team.teamName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" name="projectDescription" value={formData.projectDescription} onChange={handleInputChange} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Employee Code Column</Form.Label>
                            <Form.Select className="wt-400" name="empId" value={formData.empId} onChange={handleInputChange}>
                                <option >Select Employee Code Field</option>
                                {columnsList?.map((header) => (
                                    <option key={header.id} value={header.headerId}>
                                        {header.headerValue}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Data Source: </Form.Label>
                            <Form.Select className="wt-400" name="sourceId" value={formData.sourceId} onChange={handleInputChange}>
                                <option >Select Data Source</option>
                                {datasourceList?.map((source) => (
                                    <option key={source.id} value={source.id}>
                                        {source.datasourceName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>

            </Offcanvas.Body>

            <div className="offcanvas-footer justify-content-start">
                <Button
                    type="submit"
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                    onClick={handleSubmit}
                >
                    <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                    {loading.loading && loading?.value === "projectList" ? (
                            <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                        ) : (
                            <span className="align-middle">Create Project</span>
                        )}
                   
                </Button>
            </div>
        </Offcanvas>
    );
}
