// export class Environment {
    const live = "https://arauditgateway.cxengine.net/api/";
    //  const live = "https://audittool.innovalglobal.com:800/api/";
     const local = "https://localhost:44352/api/";
    const stage = "";
    const defaultValue = "00000000-0000-0000-0000-000000000000";
    const baseUrl = stage === "dev" ? local : live;
    const USER_URL = `${baseUrl}User/`;
    const AUTH_URL = `${baseUrl}Auth/`;
    const AUDIT_URL = `${baseUrl}Audit/`;
    const INVENTORY_URL = `${baseUrl}Inventory/`;
    const SETTING_URL = `${baseUrl}Setting/`;
    const PROJECT_URL = `${baseUrl}Project/`;
  
    
    export default {
        USER_URL,AUTH_URL,AUDIT_URL,INVENTORY_URL,defaultValue,baseUrl,SETTING_URL,PROJECT_URL
    }