import React, { useState } from "react";
import Select from "react-select";
import { Button, Card, Form, Offcanvas } from "react-bootstrap";

export default function AddErrorsModal(props) {
    const errorsOption = [
        { value: "1", label: "Missing Data", category: "Data Integrity Errors" },
        { value: "2", label: "Duplicate Entry", category: "Authorization & Security Errors" },
        { value: "3", label: "Invalid Date Format", category: "Identification & Matching Errors" },
        { value: "4", label: "Incorrect Payor Name", category: "Formatting & Validation Errors" },
        { value: "5", label: "Policy Number Mismatch", category: "Operational & Processing Errors" }
    ];


    const [selectedError, setSelectedError] = useState(null);
    const [addedError, setAddedError] = useState([]);
    // Add selected error to the table
    const addErrorToTable = () => {
        if (selectedError && !addedError.includes(selectedError)) {
            setAddedError([...addedError, selectedError]);
            setSelectedError(null); // Reset the select field
        }
    };

    // Remove error from the table
    const removeError = (error) => {
        setAddedError(addedError.filter((item) => item.value !== error.value));
    };


    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Error</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="d-flex justify-content-end gap-2 mb-4">
                        <Select className="wt-400"
                            options={errorsOption}
                            value={selectedError}
                            onChange={setSelectedError}
                            isSearchable={true}
                            placeholder="Select Auditor" />
                        <Button type="button" variant="dark" onClick={addErrorToTable}>ADD</Button>
                    </div>

                    {addedError.length === 0 ? (

                        <div className="text-center border-0 pt-3 pb-3 mt-5" style={{backgroundColor: "#f4f4f4"}}>No errors added</div>
                    ) : (
                        addedError.map((item, index) => (
                            <div className="mb-3 p-3 add-error-lists" key={index}>
                                <Button variant="default" className="btn-icon position-absolute end-30" onClick={() => removeError(item)}>
                                    <i className="ri-close-line fs-18"></i>
                                </Button>
                                <div className="mb-2">
                                    <h5 className="fs-14">{item.label}</h5>
                                    <h6 className="fs-12">{item.category}</h6>
                                </div>
                                <Form.Control as="textarea" className="error-comment" placeholder="Comment..." />
                            </div>
                        ))
                    )}

                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <span className="align-middle">Add Error</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}