import React, { useEffect } from "react";
import { Badge, Button, Card, Offcanvas, Tab, Tabs } from "react-bootstrap";
import AuditErrors from "./Pages/audit-errors";
import MappedFields from "./Pages/mapped-fields";
import MappedUsers from "./Pages/mapped-projects";
import { useDispatch } from "react-redux";
import { GetProjectListByAuditFormId, MapAuditFormErrorList, MapProjectAuditFormList } from "../../application/action/projectAction";

export default function UpdateAuditForm(props) {
    console.log(props);
    
    const dispatch = useDispatch();
    useEffect(() => {
        if (props?.data?.id !== undefined) {
            dispatch(MapAuditFormErrorList(props?.data?.id))
            dispatch(GetProjectListByAuditFormId(props?.data?.id))
        }
    }, [props?.data])
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Audit Form</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="card-one h-auto mb-4">
                        <Card.Body>
                            <div className="position-absolute end-10">
                                <Button>Edit Audit Form</Button>
                            </div>
                            <div className="mb-4">
                                <h5 className="fs-12 text-dark">Audit Form Name</h5>
                                <h2 className="fs-16 text-dark d-flex align-items-center mb-1 fw-semibold">{props?.data?.auditFormName}</h2>
                            </div>
                            <div>
                                <h5 className="fs-12 text-dark">Description</h5>
                                <h3 className="fs-14">{props?.data?.auditFormDescription}</h3>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className="common-tabs">
                        <Tabs defaultActiveKey="audit_errors_tab" className="sale-on-point-tabs">
                            <Tab eventKey="audit_errors_tab" title={<><h6 className="fs-14 mb-0">Audit Errors</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <AuditErrors data={props?.data} />
                                </div>
                            </Tab>

                            {/* <Tab eventKey="mapped_fields_tab" title={<><h6 className="fs-14 mb-0">Mapped Fields <Badge pill>6</Badge></h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedFields />
                                </div>
                            </Tab> */}

                            <Tab eventKey="mapped_projects_tab" title={<><h6 className="fs-14 mb-0">Mapped Projects <Badge pill>2</Badge></h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedUsers />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}