import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function SignupComponent() {
    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                {/* LEFT AREA */}
                <Col md="7" lg="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Card.Title>Sign Up</Card.Title>
                            <Card.Text>It's free to signup and only takes a minute.</Card.Text>
                        </Card.Header>

                        <Card.Body>
                            <div className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="text" placeholder="Enter your email address" />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter your password" />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your full name" />
                            </div>
                            <div className="mb-4">
                                <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
                            </div>

                            <Button variant="primary" className="btn-sign">Create Account</Button>
                        </Card.Body>

                        <Card.Footer>
                            Already have an account? <Link to="/account/login">Sign In</Link>
                        </Card.Footer>
                    </Card>
                </Col>

                {/* RIGHT AREA */}
                <Col className="d-none d-lg-block">
                </Col>
            </Row>
        </div>
    )
}