import teamsAPI from "./teamsAPI";
import userAPI from "./userAPI";
import errorAPI from "./errorAPI";
import inventoryAPI from "./inventoryAPI"
import projectAPI from "./projectAPI";
import auditAPI from "./auditAPI";

export default {
  teamsAPI,
  userAPI,
  errorAPI,
  inventoryAPI,
  projectAPI,
  auditAPI
}