import React from "react";
//import Forbidden from "../pages/Forbidden";
//import ForgotPassword from "../pages/ForgotPassword";
//import InternalServerError from "../pages/InternalServerError";
//import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
//import ServiceUnavailable from "../pages/ServiceUnavailable";
//import Signin from "../pages/Signin";
//import Signin2 from "../pages/Signin2";
//import Signup from "../pages/Signup";
//import Signup2 from "../pages/Signup2";
//import VerifyAccount from "../pages/VerifyAccount";

// 
import LoginComponent from "../view/Account/login";
import SignupComponent from "../view/Account/signup";
import ForgotPasswordComponent from "../view/Account/forgot-password";
import ResetPassword from './../view/Account/resetPassword';

const publicRoutes = [
  { path: "pages/error-404", element: <NotFound /> },
  { path: "", element: <LoginComponent /> },
  { path: "account/login", element: <LoginComponent /> },
  { path: "account/signup", element: <SignupComponent /> },
  { path: "account/forgot-password", element: <ForgotPasswordComponent /> },
  { path: "account/reset-password", element: <ResetPassword /> },
];

export default publicRoutes;