
import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const ErrorList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.SETTING_URL}GetAllError`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const ErrorCategoryList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.SETTING_URL}GetAllErrorCategory`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UploadError = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.SETTING_URL}UploadErrorType`,data,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
  const UploadErrorCategory = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    
    const response = await jwtInterceptor.post(`${Environment.SETTING_URL}UploadErrorCategory`,data,{
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${storedToken}`,
        },
      }
    );
    return response;
  };
export default {
    ErrorList,ErrorCategoryList,UploadError,UploadErrorCategory
  }