export const PAGE_LOADED = '[ui] page loaded';
export const SET_LOADING_ON = '[ui] set loading on';
export const SET_LOADING_OFF = '[ui] set loading off';
export const LOAD_SUCCESS= 'load_success'
export const LOAD_FAILURE= 'load_failure'


// PAGE LOADED
export const pageLoaded = {
    type: PAGE_LOADED
};

// SET LOADING
export const setLoading = (data) => ({
    type: data.loading ? SET_LOADING_ON : SET_LOADING_OFF,
    payload: data,
});

// LOAD SUCCESS
export const loadSuccess = (load) => ({
    type: LOAD_SUCCESS,
    payload: load,
});

// LOAD FAILURE
export const loadFailure = (load) => ({
    type: LOAD_FAILURE,
    payload: load,
});