import React from "react";
import Header from "../../../layouts/Header";

export default function SupervisorRebuttals() {
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Rebuttals</h4>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}