import { GET_ERROR_CATEGORY_SUCCESS, GET_ERROR_LIST_SUCCESS } from "../action/errorAction";

const initialState = {
    ErrorList: undefined,
    ErrorCategoryList: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ERROR_LIST_SUCCESS:
            return { ...state, ErrorList: action.payload.data, error: null };
        case GET_ERROR_CATEGORY_SUCCESS:
            return { ...state, ErrorCategoryList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;